import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "In welchem Consulting Team ist Christian Bumann?",
        value: "In welchem Consulting Team ist Christian Bumann?"
    },
    { text: "Erklär mir bitte die teccle gruppe.", value: "Erklär mir bitte die teccle gruppe." },
    { text: "Was bietet Consulting Team 6 an?", value: "Was bietet Consulting Team 6 an?" }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
